
import { defineComponent } from "vue";
import ProductsTopSdkIcon from "@/components/atoms/icon/ProductsTopSdkIcon/ProductsTopSdkIcon.vue";
import ProductsTopCloudIcon from "@/components/atoms/icon/ProductsTopCloudIconIcon/ProductsTopCloudIcon.vue";
import ProductsTopRtcIcon from "@/components/atoms/icon/ProductsTopRtcIcon/ProductsTopRtcIcon.vue";

export default defineComponent({
  components: {
    ProductsTopSdkIcon,
    ProductsTopCloudIcon,
    ProductsTopRtcIcon
  }
});
