
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    serviceList: {
      type: Object
    },
    dataName: {
      type: String
    }
  },
  setup() {
    const iconPath = computed(() => (name: string) =>
      `/resource/icons/icn-product-${name}.png`
    );
    const iconPath2x = computed(() => (name: string) =>
      `/resource/icons/icn-product-${name}-2x.png`
    );

    return {
      iconPath,
      iconPath2x
    };
  }
});
