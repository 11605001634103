import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f3a4808"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-service" }
const _hoisted_2 = { class: "product-service-wrap" }
const _hoisted_3 = ["src", "srcset"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "product-service-item",
          key: index
        }, [
          (_ctx.dataName === 'liveconf')
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.iconPath(`conf-${index + 1}`),
                srcset: 
              `${_ctx.iconPath(`conf-${index + 1}`)}, 
              ${_ctx.iconPath2x(`conf-${index + 1}`)} 2x`
            ,
                alt: "icon-product"
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: _normalizeClass(
              `sprite-ico sprite-ico-${_ctx.dataName} sprite-ico-${_ctx.dataName}-service${index +
                1}`
            )
              }, null, 2)),
          _createElementVNode("strong", {
            class: "title",
            innerHTML: item.title
          }, null, 8, _hoisted_4),
          _createElementVNode("p", {
            class: "description",
            innerHTML: item.description
          }, null, 8, _hoisted_5)
        ]))
      }), 128))
    ])
  ]))
}