import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43719759"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "box-products-list" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["box-products", `box-product-${_ctx.dataName}`])
  }, [
    _createElementVNode("h3", {
      class: "box-products-title",
      innerHTML: _ctx.title
    }, null, 8, _hoisted_1),
    _createElementVNode("p", {
      class: "box-products-description",
      innerHTML: _ctx.description
    }, null, 8, _hoisted_2),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productList, (item, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createElementVNode("span", {
            class: _normalizeClass(
            `sprite-ico sprite-ico-${_ctx.dataName} sprite-ico-${_ctx.dataName}-product${index +
              1}`
          )
          }, null, 2),
          _createElementVNode("strong", {
            class: "box-products-text",
            innerHTML: item.description
          }, null, 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ], 2))
}