import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isLocalImg)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "banner",
        style: _normalizeStyle({
      'background-image': `-webkit-image-set(${_ctx.imgUrl} 1x, ${_ctx.imgUrl2x} 2x)`
    })
      }, null, 4))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "banner",
        style: _normalizeStyle({
      background: _ctx.imgUrl
    })
      }, null, 4))
}