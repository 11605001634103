
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    imgName: {
      type: String,
      require: true
    },
    isLocalImg: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const imgUrl = props.isLocalImg
      ? `url(/resource/images/img-product-${props.imgName}.png)`
      : `url(${process.env.VUE_APP_META_KAGE}/images/bg/img-compact-${props.imgName}.png)`;

    const imgUrl2x = props.isLocalImg
      ? `url(/resource/images/img-product-${props.imgName}-2x.png)`
      : "";

    return {
      imgUrl,
      imgUrl2x
    };
  }
});
