import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f67d9c2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "products-spec" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "descset" }
const _hoisted_4 = { class: "desc" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "desc" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLink = _resolveComponent("AppLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("strong", {
      class: "products-spec-title",
      innerHTML: _ctx.spec.title
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("dl", _hoisted_4, [
        _createElementVNode("dt", {
          innerHTML: _ctx.spec.web.title
        }, null, 8, _hoisted_5),
        _createElementVNode("dd", {
          innerHTML: _ctx.spec.web.description
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("dl", _hoisted_7, [
        _createElementVNode("dt", {
          innerHTML: _ctx.spec.mobile.title
        }, null, 8, _hoisted_8),
        _createElementVNode("dd", {
          innerHTML: _ctx.spec.mobile.description
        }, null, 8, _hoisted_9)
      ])
    ]),
    _createVNode(_component_AppLink, {
      class: "btn btn-primary",
      text: '상세스펙 확인하기',
      target: "_blank",
      href: "https://docs.kakaoi.ai/connect_live/overview/",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('linkSpecClick')))
    })
  ]))
}