import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cont-wrap" }
const _hoisted_2 = { class: "cont-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductsBoxContainerMobile = _resolveComponent("ProductsBoxContainerMobile")!
  const _component_ProductsServiceMobile = _resolveComponent("ProductsServiceMobile")!
  const _component_ProductsSpecMobile = _resolveComponent("ProductsSpecMobile")!
  const _component_ProductsBannerImageMobile = _resolveComponent("ProductsBannerImageMobile")!
  const _component_ProductsSubjectMobile = _resolveComponent("ProductsSubjectMobile")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ProductsBoxContainerMobile, {
      title: _ctx.livecall.platform.title,
      description: _ctx.livecall.platform.description,
      productList: _ctx.livecall.platform.list,
      dataName: _ctx.livecall.name
    }, null, 8, ["title", "description", "productList", "dataName"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ProductsServiceMobile, {
        serviceList: _ctx.livecall.service.list,
        dataName: _ctx.livecall.name
      }, null, 8, ["serviceList", "dataName"])
    ]),
    _createVNode(_component_ProductsSpecMobile, { onLinkSpecClick: _ctx.trackEventSpecLivecall }, null, 8, ["onLinkSpecClick"]),
    _createVNode(_component_ProductsBannerImageMobile, {
      imgName: _ctx.livecall.name
    }, null, 8, ["imgName"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ProductsSubjectMobile, {
        title: _ctx.livecall.subject.title,
        subjectList: _ctx.livecall.subject.list,
        dataName: _ctx.livecall.name
      }, null, 8, ["title", "subjectList", "dataName"])
    ])
  ], 64))
}