
import { defineComponent } from "vue";
import { data } from "@/shared/banner";
// import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import SolutionBottomIcon from "@/components/atoms/icon/SolutionsBottomIcon/SolutionsBottomIcon.vue";

export default defineComponent({
  components: {
    // AppLink,
    SolutionBottomIcon
  },
  emits: ["linkConsultingClick"],
  setup() {
    const { title, url } = data.mobile;
    return {
      title,
      url
    };
  }
});
