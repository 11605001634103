
import { defineComponent, onMounted } from "vue";
import { trackerSetAction, trackerSetClick } from "@/utils/vertebraSdk";
import { gtagEvents } from "@/shared/gtagEvents";
import { useHead } from "@vueuse/head";
import { data } from "@/shared/products";
import ProductsBoxContainerMobile from "@/components/templates/ProductsBoxContainer/ProductsBoxContainerMobile.vue";
import ProductsServiceMobile from "@/components/templates/ProductsService/ProductsServiceMobile.vue";
import ProductsSpecMobile from "@/components/templates/ProductsSpec/ProductsSpecMobile.vue";
import ProductsBannerImageMobile from "@/components/templates/ProductsBannerImage/ProductsBannerImageMobile.vue";
import ProductsSubjectMobile from "@/components/templates/ProductsSubject/ProductsSubjectMobile.vue";
export default defineComponent({
  name: "ProductLiveCast",
  components: {
    ProductsBoxContainerMobile,
    ProductsServiceMobile,
    ProductsSpecMobile,
    ProductsBannerImageMobile,
    ProductsSubjectMobile
  },
  setup() {
    useHead({
      link: [
        {
          rel: "canonical",
          href: `${process.env.VUE_APP_BASE_URL}/products/livecast`
        }
      ]
    });
    const trackEventSpecLivecast = () => {
      gtagEvents("cast-상세스펙");
      trackerSetClick("go-cast-spec");
    };
    const { livecast } = data.mobile;
    onMounted(() => {
      trackerSetAction("상품 Cast 페이지");
    });
    return {
      livecast,
      trackEventSpecLivecast
    };
  }
});
