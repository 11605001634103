
import { computed, defineComponent } from "vue";
import { trackerSetClick } from "@/utils/vertebraSdk";
import { useRoute } from "vue-router";
import { gtagEvents } from "@/shared/gtagEvents";
import { useHead } from "@vueuse/head";
import { ROUTER_PATH as list } from "@/shared/routerPathMobile";
import { data } from "@/shared/products";
import SubIntroContentMobile from "@/components/templates/SubIntroContent/SubIntroContentMobile.vue";
import ProductsPrimaryBoxMobile from "@/components/templates/ProductsPrimaryBox/ProductsPrimaryBoxMobile.vue";
import ProductsFeaturesMobile from "@/components/templates/ProductsFeatures/ProductsFeaturesMobile.vue";
import SiteShortcutMobile from "../../components/templates/SiteShortcut/SiteShortcutMobile.vue";
import SubBannerSignupMobile from "@/components/templates/SubBannerSignup/SubBannerSignupMobile.vue";
export default defineComponent({
  components: {
    SubIntroContentMobile,
    ProductsPrimaryBoxMobile,
    ProductsFeaturesMobile,
    SiteShortcutMobile,
    SubBannerSignupMobile
  },
  setup() {
    useHead({ title: data.sitemapTitle });
    const trackEventConsultingLivecall = () => {
      gtagEvents("call-문의하기-footer");
      trackerSetClick("ask-call-footer");
    };
    const trackEventConsultingLivecast = () => {
      gtagEvents("cast-문의하기-footer");
      trackerSetClick("ask-cast-footer");
    };
    const trackEventConsultingLiveconference = () => {
      gtagEvents("conference-문의하기-footer");
      trackerSetClick("ask-conference-footer");
    };
    const route = useRoute();
    const pathName = computed(() => route.name);
    const trackEventsInit = () => {
      switch (pathName.value) {
        case "ProductLiveCall":
          trackEventConsultingLivecall();
          break;
        case "ProductLiveCast":
          trackEventConsultingLivecast();
          break;
        case "ProductLiveConference":
          trackEventConsultingLiveconference();
          break;
        default:
          break;
      }
    };
    const items = list[0].children;
    const { title, description } = data.mobile;

    return {
      items,
      title,
      description,
      pathName,
      trackEventsInit
    };
  }
});
