
import { defineComponent } from "vue";
import ProductsFeatureServerIcon from "@/components/atoms/icon/ProductsFeatureServerIcon/ProductsFeatureServerIcon.vue";
import ProductsFeatureSdkIcon from "../../atoms/icon/ProductsFeatureSdkIcon/ProductsFeatureSdkIcon.vue";
import ProductsFeatureGlobalIcon from "../../atoms/icon/ProductsFeatureGlobalIcon/ProductsFeatureGlobalIcon.vue";
import ProductsFeatureRecIcon from "../../atoms/icon/ProductsFeatureRecIcon/ProductsFeatureRecIcon.vue";
import ProductsFeatureStaticIcon from "../../atoms/icon/ProductsFeatureStaticIcon/ProductsFeatureStaticIcon.vue";
import ProductsFeatureMsgIcon from "../../atoms/icon/ProductsFeatureMsgIcon/ProductsFeatureMsgIcon.vue";
import ProductsFeatureAiIcon from "../../atoms/icon/ProductsFeatureAiIcon/ProductsFeatureAiIcon.vue";
import ProductsFeatureTable from "../ProductsFeatureTable/ProductsFeatureTable.vue";

export default defineComponent({
  components: {
    ProductsFeatureServerIcon,
    ProductsFeatureSdkIcon,
    ProductsFeatureGlobalIcon,
    ProductsFeatureRecIcon,
    ProductsFeatureStaticIcon,
    ProductsFeatureMsgIcon,
    ProductsFeatureAiIcon,
    ProductsFeatureTable
  }
});
