
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    dataName: {
      type: String
    },
    title: {
      type: String
    },
    subjectList: {
      type: Object,
      require: true
    }
  },
  setup() {
    const imgSrc = process.env.VUE_APP_META_KAGE + "/images/common";

    return {
      imgSrc
    };
  }
});
