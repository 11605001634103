
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    productList: {
      type: Object
    },
    dataName: {
      type: String
    }
  }
});
